import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { BASE_URL } from "../../url/baseurl";
import { Link, useParams, useHistory } from "react-router-dom";
import ReactModal from "react-modal";
import moment from "moment";
import { DataGrid, GridColumns, GridRowsProp } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import { Tooltip } from "antd";
const theme = localStorage.getItem('brandColor');
const StyledTable = styled.table`
  width: 100%;
`;

const StyledButton = styled.button`
  background: ${theme};
  width: 150px;
  border-radius: 30px;
  height: 40px;
  text-align: center;
  font-size: 16px !important;
  color: white;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 5px;
  padding: 5px 10px;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;
const StyledTableRow = styled.tr`
  font-weight: bold;
  font-family: Nunito;
  font-style: normal;
  border-bottom: 1px solid #9d9d9d;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding-left: 5px;
  font-family: Nunito;
  font-style: normal;
`;

const ModalStyle = {
  content: {
    width: "500px",
    height: "300px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    justifyItem: "center",

    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
  },
};

const StyledForm = styled.form`
  display: flex;
  /* justify-content: center; */
  //   margin-left: 20%;
  flex-direction: column;
`;

const StyledStatus = styled.td`
  color: green;
`;

const StyledInputDiv = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;
  width: 100%;
  min-width: 500px;
  height: auto;
  justify-self: center;
  text-align: center;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  border-radius: 50px;

  margin-top: 50px;
`;

const StyledDiv1 = styled.div`
  margin: 13.5px;
`;
const StyledInput = styled.input`
  border: 1px solid #e4e4e4;
  border-top: none;
  border-left: none;
  border-right: none;

  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  color: #afaaaa;

  &::active {
    border: 1px solid #e4e4e4;
    border-top: none;
    border-left: none;
    border-right: none;
  }
`;
const StyledButton1 = styled.button`
  background: ${theme};
  width: auto;
  border-radius: 20px;
  height: 25px;
  text-align: center;
  color: white;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 15px;
`;

const StyledButtonE = styled.button`
  background: ${theme};
  width: 200px;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const HorizontalDivider = {
  width: "36px",
  height: "0px",
  marginTop: "2%",
  border: "0.5px solid #C5C1C1",
  transform: "rotate(90deg)",
};

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--cell": {
      backgroundColor: "rgba(224, 183, 60, 0.55)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.negative": {
      backgroundColor: "rgba(157, 255, 118, 0.49)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.positive": {
      // backgroundColor: '#d47483',
      border: "1px solid #ff8c24",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app-theme--header": {
      backgroundColor: "#ff8c24",
      border: "1px solid #ff8c24",
      color: "#FFFFFF",
      fontWeight: "600",
    },
  },
});




function Edittopic() {
  const history = useHistory();
  const classes = useStyles();

  //   ----------------------------------------------edit topic api call---------------------------------------
  const [data1, setData1] = useState([]);
  const [userdata, setUserdata] = useState();
  const [called_func_name, setCalled_func_name] = useState("");

  const [edit_modal, setEdit_modal] = useState(false);

  const [edit_topic_modal, setEdit_topic_modal] = useState(false);
  const [sugg_modal, setSugg_modal] = useState(false);
  const [delete_quiz, setDelete_quiz] = useState("");

  const [take_quiz, setTake_quiz] = useState("");
  const [cont_quiz, setCont_quiz] = useState("");
  const [cuztopic, setCuztopic] = useState("");
  const [topicid, setTopicid] = useState();
  const [periodcode, setPeriodcode] = useState();
  const [perioddesc, setPerioddesc] = useState();
  const [countrycode, setCountrycode] = useState();
  const [locationid, setLocationid] = useState();
  const [locationdesc, setLocationdesc] = useState();
  const [topiccodes, setTopiccodes] = useState();

  const [data2, setData2] = useState([]);
  const [data2filter, setData2filter] = useState([]);

  const [quiz_msg, setQuiz_msg] = useState("");
  const [last_status, setLast_status] = useState("");

  // --------- filter hooks-------=-=-=-=-=-=-=-=-=-=-==

  const [cuz_desc, setCuz_desc] = useState("");
  const [filter_loc, setFilter_loc] = useState("");
  const [filter_country, setFilter_country] = useState("");
  const [filter_status, setFilter_status] = useState("");
  const [pageSize, setPageSize] = useState(100);

  // -----filter func------------------------------------------

  const filter = () => {
    let filter_data = [];

    if (cuz_desc !== "") {
      filter_data = [
        ...data2.filter((item) =>
          String(item.cuzdesc).toLowerCase().includes(cuz_desc?.toLowerCase())
        ),
      ];
    }

    if (filter_loc !== "") {
      filter_data = [
        ...data2.filter((item) =>
          String(item.locationdesc).toLowerCase().includes(filter_loc?.toLowerCase())
        ),
      ];
    }

    if (filter_country !== "") {
      filter_data = [
        ...data2.filter((item) =>
          String(item.countrycode).toLowerCase().includes(filter_country?.toLowerCase())
        ),
      ];
    }

    if (filter_status !== "") {
      filter_data = [
        ...data2.filter((item) =>
          String(item.laststatus).toLowerCase().includes(filter_status?.toLowerCase())
        ),
      ];
    }

    console.log(filter_data);

    setData2(filter_data.length != 0 ? filter_data : data2filter);
  };

  // ?customerid=${res.data.userdata[0].customerid}&useremail=${res.data.userdata[0].useremail}&userid=${userid}

  const Edit_topic_data = async () => {
    let userid = localStorage.getItem("UserID");
    let res = axios
      .get(`${BASE_URL}/api/user_session_data?userid=${userid}`, {
        userid: localStorage.getItem("UserID"),
      })
      .then((res) => {
        let P_data = {
          list: res.data.list,
          userdata: res.data.userdata,
          customerid: res.data.userdata[0].customerid,
          useremail: res.data.userdata[0].useremail,
          userid: userid,
        };
        if (res.data.code == 200) {
          axios
            .post(`${BASE_URL}/api/edit_topic_quiz`, P_data)
            .then((res) => {
              setData2(res.data.topic_information);
              setData2filter(res.data.topic_information);
              console.log(
                "dataddatadtdatdatdatdtadda",
                res.data.topic_information
              );
            })
            .catch((e) => {
              console.log(e);
            });
        }
        setUserdata(res.data.userdata);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    Edit_topic_data();
  }, []);





  const SelectButton = ({ data, setCont_quiz, setDelete_quiz, setCuztopic, setTopicid,setPeriodcode, setLocationid, setLocationdesc,setCountrycode,setTopiccodes, setPerioddesc, setLast_status, setCuz_desc,setEdit_topic_modal }) => {
    const handleSelectClick = () => {
      setTake_quiz(data.Takequiz);
                        setEdit_topic_modal(true);
                        setCont_quiz(data.Continuequiz);
                        setDelete_quiz(data.Deletequiz);
                        setCuztopic(data.cuztopic);
                        setTopicid(data.topicid);
                        setPeriodcode(data.periodcode);
                        setLocationid(data.locationid);
                        setLocationdesc(data.locationdesc);
                        setCountrycode(data.countrycode);
                        setTopiccodes(data.topiccodes);
                        setPerioddesc(data.perioddesc);
                        setLast_status(data.laststatus);
                        setCuz_desc(data.cuzdesc);

                        localStorage.setItem("CuzDesc", data.cuzdesc)
                        localStorage.setItem("CuzTopic", data.cuztopic)
                        localStorage.setItem("TopicId", data.topicid)
                        localStorage.setItem("LocationId", data.locationid)
                        localStorage.setItem("LocationDesc", data.locationdesc)
                        localStorage.setItem("TopicCodes", data.topiccodes)
                        localStorage.setItem("PeriodDesc", data.perioddesc)
                        localStorage.setItem("PeriodCode", data.periodcode)
                        localStorage.setItem("CountryCode", data.countrycode)
    };

    return (
      <StyledButton onClick={handleSelectClick} style={{ width: 100 }}>
        Select
      </StyledButton>
    );
  };



  let SNo = 1;
  const rowsWithSerialNumber = data2?.map((data2) => {
    return {
      ...data2,
      SNo: SNo++,
    };
  });


  const rows1 = rowsWithSerialNumber;
  
const columnsUsers: GridColumns = [

    // {
    //   field: "SNo",
    //   headerName: "S.No",
    //   // minwidth: 200,
    //   flex: 0.3,
    //   // type: "email",
    //   align: "center",
    //   headerAlign: "center",
    //   headerClassName: "super-app-theme--header",
    //   // renderCell: (params) => (
    //   //   <div>{params.value + 1}</div>
    //   // ),
    // },

    {
      field: "cuzdesc",
      headerName: "Topic Description",
      // minwidth: 200,
      flex: 1,
      // type: "email",
      align: "start",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            <div style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%"
            }}>
              {params.value}
            </div>
            {/* {params.value?.slice(0, 12)}... */}
            {/* {params.value?.length > 24
                ? `${params.value.substring(0, 24)}...`
                : params.value} */}
          </Tooltip>
        );
      },

    },
    {
      field: "locationdesc",
      headerName: "Location",
      // width: 200,
      // type: "number",
      flex: 1,
      align: "start",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            <div style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%"
            }}>
              {params.value}
            </div>
            {/* {params.value?.slice(0, 12)}... */}
            {/* {params.value?.length > 24
                ? `${params.value.substring(0, 24)}...`
                : params.value} */}
          </Tooltip>
        );
      },
    },
    {
      field: "countrycode",
      headerName: "Country",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 6
                ? `${params.value.substring(0, 6)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "perioddesc",
      headerName: "Period",
      type: "text",
      headerClassName: "super-app-theme--header",
      // width: 200,
      flex: 0.5,

      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 10
                ? `${params.value.substring(0, 10)}...`
                : params.value}
          </Tooltip>
        );
      },

    },

    {
      field: "laststatus",
      headerName: "Status",
      type: "text",
      headerClassName: "super-app-theme--header",
      // width: 200,
      flex: 0.5,

      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 10
                ? `${params.value.substring(0, 10)}...`
                : params.value}
          </Tooltip>
        );
      },

    },


    {
      field: "Action",
      headerName: " ",
      flex: 0.5,
      align: "center",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          <SelectButton
            data={params.row}
            setTake_quiz={setTake_quiz}
            setCont_quiz={setCont_quiz}
            setDelete_quiz={setDelete_quiz}
            setCuztopic={setCuztopic}
            setTopicid={setTopicid}
            setPeriodcode={setPeriodcode}
            setLocationid={setLocationid}
            setLocationdesc={setLocationdesc}
            setCountrycode={setCountrycode}
            setTopiccodes={setTopiccodes}
            setPerioddesc={setPerioddesc}
            setLast_status={setLast_status}
            setCuz_desc={setCuz_desc}
            setEdit_topic_modal={setEdit_topic_modal}
          />
          
        </div>
      ),
    },
  ];



  return (
    <div className="container mt-3">
      <div align="center">
        <StyledButtonE
          onClick={() => {
            history.push("/Extquestion2");
          }}
        >
          Exit
        </StyledButtonE>
      </div>
      {/* <div
        className=" EXTQ FilterS"
        style={{
          marginTop: 20,
          padding: 20,
          border: "black solid 1px",
          borderRadius: 50,
        }}
      >
        <div className="row EXTQ">
          <div className="col-2">
            <input
              placeholder="Filter Topic Description"
              onChange={(e) => {
                setCuz_desc(e.target.value);
                console.log("object", e.target.value);
              }}
              type="text"
            />
          </div>
          <div className="col-2">
            <input
              placeholder="Filter Location"
              onChange={(e) => setFilter_loc(e.target.value)}
              type="text"
            />
          </div>
          <div className="col-2">
            <input
              placeholder="Filter Country"
              onChange={(e) => setFilter_country(e.target.value)}
              type="text"
            />
          </div>
          <div className="col-2">
            <input
              placeholder="Filter Status"
              onChange={(e) => setFilter_status(e.target.value)}
              type="text"
            />
          </div>
          <div className="col-4 text-center">
            <StyledButton
              style={{ padding: 5, width: 90, height: 35 }}
              onClick={filter}
            >
              Search
            </StyledButton>
          </div>
        </div>
      </div> */}


<div
        style={{ height: '76vh', width: "100%", marginTop: 15 }}
        className={classes.root }
      >
        <DataGrid className="custom-data-grid"
          sx={{
            ".MuiDataGrid-columnHeaders": {
              backgroundColor: "#ff8c24",
            },
            "&.MuiDataGrid-root": {
              border: "none",
              //height: "100vh",
            },

            "&.MuiDataGrid-cell": {
              cursor: "help",
              backgroundColor: "black",
              color: "white",
              // other styles
            },


          }}
          rows={rows1}
          getRowId={(r) => r.id}
          id="_id"
          columns={columnsUsers}
          pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[100]}
                pagination
                hideFooterPagination={rows1.length <= pageSize}
          // initialState={{

          //   pagination: {
          //     pageSize: 10,
          //   },
          // }}
          //pagination={false}
          //hideFooterPagination={true}
         // rowsPerPageOptions={[100]}
         // pageSize={Math.min(rows1.length)}
          //pageSize={pageSize}
          //onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          //rowsPerPageOptions={[5, 10, 20, 50, 100]}
          //pagination
          //  processRowUpdate={processRowUpdate}
          experimentalFeatures={{ newEditingApi: true }}
        />

      </div>


      {/* <div className="mt-5 table-responsive tableFixHead">
        <StyledTable>
          <thead>
            <StyledTableHeader>
              <StyledTableHeaderData>S.No</StyledTableHeaderData>

              <StyledTableHeaderData>Topic Description</StyledTableHeaderData>

              <StyledTableHeaderData>Location</StyledTableHeaderData>
              <StyledTableHeaderData>Country</StyledTableHeaderData>

              <StyledTableHeaderData>Period</StyledTableHeaderData>
              <StyledTableHeaderData>Status</StyledTableHeaderData>

              <StyledTableHeaderData></StyledTableHeaderData>
            </StyledTableHeader>
          </thead>
          <tbody>
            {data2?.map((data, index) => {
              console.log("object", moment(data.perioddesc).format("MMM YYYY"));
              return (
                <StyledTableRow>
                  <td>{index + 1}</td>

                  <td>{data.cuzdesc}</td>

                  <td>{data.locationdesc}</td>
                  <td>{data.countrycode}</td>

                  <td>{data.perioddesc}</td>
                  <td>{data.laststatus}</td>

                  <td>
                    <StyledButton
                      onClick={() => {
                        setTake_quiz(data.Takequiz);
                        setEdit_topic_modal(true);
                        setCont_quiz(data.Continuequiz);
                        setDelete_quiz(data.Deletequiz);
                        setCuztopic(data.cuztopic);
                        setTopicid(data.topicid);
                        setPeriodcode(data.periodcode);
                        setLocationid(data.locationid);
                        setLocationdesc(data.locationdesc);
                        setCountrycode(data.countrycode);
                        setTopiccodes(data.topiccodes);
                        setPerioddesc(data.perioddesc);
                        setLast_status(data.laststatus);
                        setCuz_desc(data.cuzdesc);

                        localStorage.setItem("CuzDesc", data.cuzdesc)
                        localStorage.setItem("CuzTopic", data.cuztopic)
                        localStorage.setItem("TopicId", data.topicid)
                        localStorage.setItem("LocationId", data.locationid)
                        localStorage.setItem("LocationDesc", data.locationdesc)
                        localStorage.setItem("TopicCodes", data.topiccodes)
                        localStorage.setItem("PeriodDesc", data.perioddesc)
                        localStorage.setItem("PeriodCode", data.periodcode)
                        localStorage.setItem("CountryCode", data.countrycode)
                      }}
                      style={{ width: 100 }}
                    >
                      Select
                    </StyledButton>
                  </td>
                </StyledTableRow>
              );
            })}
          </tbody>
        </StyledTable>
      </div> */}

      <ReactModal style={ModalStyle} isOpen={edit_topic_modal}>
        <StyledForm>
          <div align="center">
            {moment(perioddesc).format("MMM YYYY") ===
              moment().format("MMM YYYY") && last_status == "Completed" ? (
              <div>
                <p>
                  {" "}
                  This topic has been completed for the current period. No more
                  changes can be made.
                </p>
                <div>
                  <StyledButton
                    onClick={() => {
                      setEdit_topic_modal(false);
                    }}
                    style={{ width: 100, heigth: 50, backgroundColor: "red" }}
                  >
                    Cancel
                  </StyledButton>
                </div>
              </div>
            ) : (
              <>
                {take_quiz == "Y" ? (
                  <StyledButton
                    onClick={() => {
                      setQuiz_msg("newquiz");

                      let msg = "newquiz";
                      history.push(
                        `/start_test/${msg}`
                      );
                    }}
                    style={{ width: 300 }}
                  >
                    Take New Questionnaire
                  </StyledButton>
                ) : (
                  ""
                )}

                {cont_quiz == "Y" ? (
                  <StyledButton
                    onClick={() => {
                      setQuiz_msg("contquiz");

                      let msg = "contquiz";
                      history.push(
                        `/start_test/${msg}`
                      );
                    }}
                    style={{ width: 300 }}
                  >
                    Continue with Questionnaire
                  </StyledButton>
                ) : (
                  ""
                )}

                {delete_quiz == "Y" ? (
        
                  <StyledButton
                    onClick={() => {
                      setQuiz_msg("delquiz");

                      let msg = "delquiz";
                      history.push(
                        `/start_test/${msg}`
                      );
                    }}
                    style={{ width: 300, marginTop: 10 }}
                  >
                    Delete Data & Begin Again
                  </StyledButton>
                ) : (
                  ""
                )}

                {/* {last_status == "Initial" ? (
                  <StyledButton
                    onClick={() => {
                      setQuiz_msg("delquiz");

                      let msg = "delquiz";
                      history.push(
                        `/start_test/${msg}`
                      );
                    }}
                    style={{ width: 300, marginTop: 10 }}
                  >
                    Start Questionnaire
                  </StyledButton>
                ) : (
                  ""
                )} */}

                <div className="mb-3 d-flex justify-content-around">
                  <StyledButton
                    onClick={() => {
                      setEdit_topic_modal(false);
                    }}
                    style={{ backgroundColor: "red", marginTop: 10 }}
                  >
                    Cancel
                  </StyledButton>
                </div>
              </>
            )}
          </div>
        </StyledForm>
      </ReactModal>
    </div>
  );
}

export default Edittopic;
