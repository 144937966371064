import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { message } from "antd";
import { DataGrid, GridColumns, GridRowsProp } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import { Tooltip } from "antd";
import { Checkbox } from "@mui/material";
import {
  sectionselect_stdtopics_api,
  selection_newtopic_api,
  selection_viewstatus_api,
  selection_topicstatus_api,
  selection_statusinfo_api,
  selection_bulk_topicstatus_api
} from "../../../services/AdminProgramApi";
const theme = localStorage.getItem('brandColor');
const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 30%;
`;

const StyledFormInputDiv = styled.div`
  display: flex;
  margin-top: 4px;
  width: 100%;
`;

const FormDivStyle = {
  position: "relative",
  display: "inline",
  width: "100%",
  textAlign: "left"
};

const StyledButton = styled.button`
  background: ${theme};
  width: 100%;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const StyledButtonSave = styled.button`
  background: ${theme};
  width: 100%;
  border-radius: 30px;
  height: 27px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
`;

const ScrollTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;
const StyledTableRow = styled.tr`
  border-bottom: 1px solid #9d9d9d;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding 5px;
  font-family: Nunito;
  font-style: normal;
`;

const StyledButton1 = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  font-size: 18px !important;

  color: white;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 15px;
  padding: 5px 10px;
`;

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--cell": {
      backgroundColor: "rgba(224, 183, 60, 0.55)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.negative": {
      backgroundColor: "rgba(157, 255, 118, 0.49)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.positive": {
      // backgroundColor: '#d47483',
      border: "1px solid #ff8c24",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app-theme--header": {
      backgroundColor: "#ff8c24",
      border: "1px solid #ff8c24",
      color: "#FFFFFF",
      fontWeight: "600",
    },
  },
});



const Standard_Topics = ({ match }) => {
  const history = useHistory();
  const classes = useStyles();
  console.log("propdata", match);

  const [table_name, setTable_name] = useState("selected_topic");

  const [status_info_modal, setStatus_info_modal] = useState(false);
  const [edit_std_tpc_modal, setEdit_std_tpc_modal] = useState(false);
  const [availableTopics, setAvailableTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [status, setStatus] = useState("");

  // -----------------------------------
  const [tstatus_id, setTstatus_id] = useState("");
  const [topicid, setTopicid] = useState();
  const [topiccodes, setTopiccodes] = useState();
  const [topicdesc, setTopicdesc] = useState("");
  const [status1, setStatus1] = useState("");

  // --------------------- Sorting Hooks ----------------------
  const [sortBySno, setSortBySno] = useState(true);
  const [sortByAvlTopic, setSortByAvlTopic] = useState(false);
  const [sortRev, setSortRev] = useState(false);

  // -------------------- Filter Hooks-----------------------

  const [data2filter, setData2filter] = useState([]);

  const [topic_id, setTopic_id] = useState("");
  const [filter_topic, setFilter_topic] = useState("");
  const [pageSize, setPageSize] = useState(100);
  const [selectedRows, setSelectedRows] = useState([]);
  const [quickActivate, setquickActivateText] = useState("Quick Activate");
  // ---------------------------Filter Function-------------------------------

  const filter_Function = () => {
    let filter_data = [];

    if (topic_id !== "") {
      filter_data = [
        ...filter_data,
        ...availableTopics.filter((item) =>
          String(item.topicid).toLowerCase().includes(topic_id?.toLowerCase())
        ),
      ];
    }

    if (filter_topic !== "") {
      filter_data = [
        ...filter_data,
        ...availableTopics.filter((item) =>
          String(item.topicdesc)
            .toLowerCase()
            .includes(filter_topic?.toLowerCase())
        ),
      ];
    }

    setAvailableTopics(filter_data.length != 0 ? filter_data : data2filter);
    console.log(availableTopics);
  };

  const customerid = match.params.customerid;

  // ----------------------------sectionselect_stdtopics_api_func call----------------------

  const sectionselect_stdtopics_api_func = async () => {
    sectionselect_stdtopics_api(customerid)
      .then((res) => {
        if (res.data.code == 200) {
          console.log("sectionselect_stdtopics_api res===>", res.data);
          setSelectedTopics(res.data.Selectedtops);
          setData2filter(res.data.Availabletops);
          // setAvailableTopics(res.data.Availabletops)
        }
        if (sortBySno) {
          setAvailableTopics(
            sortRev ? res.data.Availabletops?.reverse() : res.data.Availabletops
          );
        } else if (sortByAvlTopic) {
          setAvailableTopics(
            sortRev
              ? res.data.Availabletops?.sort((a, b) => {
                const x = a.topicdesc.toUpperCase();
                const y = b.topicdesc.toUpperCase();
                return x === y ? 0 : x > y ? 1 : -1;
              })?.reverse()
              : res.data.Availabletops?.sort((a, b) => {
                const x = a.topicdesc.toUpperCase();
                const y = b.topicdesc.toUpperCase();
                return x === y ? 0 : x > y ? 1 : -1;
              })
          );
        } else {
          setAvailableTopics(res.data.Availabletops);
        }
      })
      .catch((err) => {
        console.log("sectionselect_stdtopics_api err==>>", err);
      });
  };

  // --------selection_newtopic_api on save click-------------------

  const selection_newtopic_api_func = async (topiccode) => {
    let data = {
      customerid: customerid,
      topiccode: topiccode,
    };
    selection_newtopic_api(data)
      .then((res) => {
        if (res.data.code == 200) {
          sectionselect_stdtopics_api_func();
          message.success("Topic added successfully");
        } else message.error(res.data.message);
      })
      .catch((err) => {
        console.log("selection_newtopic_api error==>", err);
      });
  };

  useEffect(() => {
    sectionselect_stdtopics_api_func();
  }, [customerid, sortRev, sortBySno, sortByAvlTopic]);
  // --------------------------selection_viewstatus func call---------------------
  const [selection_viewstatuslist, setSelection_viewstatuslist] = useState([]);

  const selection_viewstatus_api_func = async () => {
    selection_viewstatus_api()
      .then((res) => {
        if (res.data.code == 200) {
          console.log("selection_viewstatus_api res==>", res.data);
          setSelection_viewstatuslist(res.data.Data);
        }
      })
      .catch((err) => {
        console.log("selection_viewstatus_api error==>", err);
      });
  };

  useEffect(() => {
    selection_viewstatus_api_func();
  }, [customerid]);

  // -------------------selection_topicstatus api func call----------------

  const selection_topicstatus_api_func = () => {
    let data = {
      topicid: topicid,
      tstatusid: tstatus_id,
    };
    selection_topicstatus_api(data)
      .then((res) => {
        if (res.data.code == 200) {
          message.success("Updated Succesfully");
          sectionselect_stdtopics_api_func();
          setEdit_std_tpc_modal(false);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log("updated error==>", err);
      });
  };

  const selection_bulk_topicstatus_api_func = () => {
    let data = {
      topicid: selectedRows,
    };
    console.log("data",data);
    if(!selectedRows || selectedRows.length === 0){
      message.error("Select at least one topic to Activate");
      setquickActivateText("Quick Activate");
    }
    else{
      selection_bulk_topicstatus_api(data)
      .then((res) => {
        if (res.data.code == 200) {
          message.success("Activated Successfully");
          setquickActivateText("Quick Activate");
          sectionselect_stdtopics_api_func();
          setSelectedRows([]);
          //setEdit_std_tpc_modal(false);
        } else {
          setquickActivateText("Quick Activate");
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log("updated error==>", err);
      });
    }
    
  };

  // -------------------selection_statusinfo_api func call===========------------------
  const [select_status_infolist, setSelect_status_infolist] = useState([]);
  const selection_statusinfo_api_func = async () => {
    selection_statusinfo_api()
      .then((res) => {
        if (res.data.code == 200) {
          console.log("selection_statusinfo_api res==>", res.data);
          setSelect_status_infolist(res.data.Data);
        }
      })
      .catch((err) => {
        console.log("selection_statusinfo_api error==>", err);
      });
  };

  //selected topics


  const EditstandardButton = ({
    data,
    setStatus1,
    setTopicid,
    setTopiccodes,
    setTopicdesc,
    setEdit_std_tpc_modal,
  }) => {
    const handleEditClick = () => {
      setStatus1(data.tstatusid)
      setTstatus_id(data.tstatusid);
      setTopicid(data.topicid);
      setTopiccodes(data.topiccodes);
      setTopicdesc(data.topicdesc);
      setEdit_std_tpc_modal(true);
      console.log(status1, "Tstatus_id")
      console.log(topicdesc, "Topicdesc")
    };

    return (
      <a onClick={handleEditClick}>
        <i class="fas fa-pencil-alt" aria-hidden="true"></i>
      </a>
    );
  };


  const InfoButton = ({ data, setStatus_info_modal, selection_statusinfo_api_func }) => {
    const handleInfoClick = () => {
      setStatus_info_modal(true);
     selection_statusinfo_api_func();
    };
  
    return (
      <a onClick={handleInfoClick}>
        <i
                        class="fas fa-info-circle"
                        style={{ marginLeft: 5 }}
                      ></i>
      </a>
    );
  };



  let SNo = 1;
  const rowsWithSerialNumber = selectedTopics.map((selectedTopics) => {
    return {
      ...selectedTopics,
      SNo: SNo++,
    };
  });


  const rows1 = rowsWithSerialNumber;

  const columnsUsers: GridColumns = [

    

    {
      field: "SNo",
      headerName: "S.No",
      // minwidth: 200,
      flex: 0.3,
      // type: "email",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      // renderCell: (params) => (
      //   <div>{params.value + 1}</div>
      // ),
    },

    {
      field: "topicdesc",
      headerName: "Selected Topics",
      // minwidth: 200,
      flex: 1,
      // type: "email",
      align: "start",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            <div style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%"
            }}>
              {params.value}
            </div>
            {/* {params.value?.slice(0, 12)}... */}
            {/* {params.value?.length > 40
                ? `${params.value.substring(0, 40)}...`
                : params.value} */}
          </Tooltip>
        );
      },

    },
    {
      field: "tstatusid",
      headerName: "Status",
      // width: 200,
      // type: "number",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 12
                ? `${params.value.substring(0, 12)}...`
                : params.value}
          </Tooltip>
        );
      },

    },
    {
      field: "Action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          <EditstandardButton
            data={params.row}
            setStatus1={setStatus1}
            setTstatus_id={setTstatus_id}
            setTopicid={setTopicid}
            setTopiccodes={setTopiccodes}
            setTopicdesc={setTopicdesc}
            //setActive={setActive}
            setEdit_std_tpc_modal={setEdit_std_tpc_modal}
          />
          &nbsp; &nbsp;
          <InfoButton
            data={params.row}
            selection_statusinfo_api_func={selection_statusinfo_api_func}
            setStatus_info_modal={setStatus_info_modal}
          />
        </div>
      ),
    },

    {
      field: "Select",
      headerName: "QuickActive",
      headerClassName: "super-app-theme--header",
      flex: 0.3,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Checkbox
          checked={params.row.tstatusid === "Active" || selectedRows.includes(params.row.topicid)}
          onChange={(event) => handleCheckboxChange(event, params.row)}
          disabled={params.row.tstatusid === "Active"}
        />
      ),
    },


  ];

// available topics


// const handleCheckboxChange = (event, row) => {
//   const isChecked = event.target.checked;

//   console.log(`Topic ID: ${row.id}, Checked: ${isChecked}`);

//   // Simulating an update to the status
//   if (isChecked) {
//     row.tstatusid = "Active"; // Ideally, update state instead of modifying row directly
//   }
// };
// const handleCheckboxChange = (event, row) => {
//   const isChecked = event.target.checked;
//   if (isChecked) {
//     setSelectedRows((prev) => [...prev, row]);
//   } else {
//     setSelectedRows((prev) => prev.filter((r) => r.id !== row.id));
//   }
// };

const handleCheckboxChange = (event, row) => {
  const isChecked = event.target.checked;
  setSelectedRows((prev) => {
    if (isChecked) {
      return [...prev, row.topicid]; // Store topicid instead of row.id
    } else {
      return prev.filter((topicid) => topicid !== row.topicid); // Remove deselected topicid
    }
  });
};

const handleQuickActive = () => {
  setquickActivateText("Quick Activating...");
  console.log("Selected Rows IDs:", selectedRows);
  selection_bulk_topicstatus_api_func();
  // Example: Disabling checkboxes if status is 'Active'
  //setSelectedRows((prev) => prev.filter((row) => row.tstatusid !== "Active"));
};

const SelectButton = ({ data, selection_newtopic_api_func}) => {
  const handleSelectClick = () => {
    selection_newtopic_api_func(data.topiccode);
  
  };

  return (
    <StyledButtonSave onClick={handleSelectClick}  style={{ textAlign: "center", width: 100 }}>
      Select
    </StyledButtonSave>
  );
};


let SNo1 = 1;
  const rowsWithSerialNumber1 = availableTopics.map((availableTopics) => {
    return {
      ...availableTopics,
      SNo1: SNo1++,
    };
  });


  const rows = rowsWithSerialNumber1;

const columnsAvailable: GridColumns = [

  {
    field: "SNo1",
    headerName: "S.No",
    // minwidth: 200,
    flex: 0.4,
   // type: "email",
    align: "center",
    headerAlign: "center",
    headerClassName: "super-app-theme--header",
    // renderCell: (params) => (
    //   <div>{params.value + 1}</div>
    // ),
  },
  
  {
    field: "topicdesc",
    headerName: "Available Topics",
    // minwidth: 200,
    flex: 1,
   // type: "email",
    align: "start",
    headerAlign: "center",
    headerClassName: "super-app-theme--header",
    renderCell: (params) => {
      return (
        <Tooltip
         
          title={params.value}
        >
          <div style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%"
            }}>
              {params.value}
            </div>
          {/* {params.value?.slice(0, 12)}... */}
          {/* {params.value?.length > 40
              ? `${params.value.substring(0, 40)}...`
              : params.value} */}
        </Tooltip>
      );
    },
    
  },
  
  {
    field: "Action",
    headerName: "Action",
    headerClassName: "super-app-theme--header",
    flex: 0.6,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <div>
        <SelectButton
          data={params.row}
        
          selection_newtopic_api_func={selection_newtopic_api_func}
        />
      </div>
    ),
  },
  
  
];



  return (
    <div className="container-fluid">
      <div className="mt-4 row text-center" align="center">
        <div className="col-4 Width100">
          <StyledButton1
            onClick={() => {
              history.push("/adminfunctionmodal");
            }}
          >
            Return to Admin Menu
          </StyledButton1>
        </div>
        <div className="col-4 Width100">
          <StyledButton1
            onClick={() => {
              setTable_name("selected_topic");
            }}
            hidden={table_name == "selected_topic" ? true : false}
          >
            Selected Topic
          </StyledButton1>
          <StyledButton1
            onClick={() => {
              setTable_name("available_topic");
            }}
            hidden={table_name == "available_topic" ? true : false}
          >
            Available Topic
          </StyledButton1>
        </div>
        <div className="col-4 Width100">
          <StyledButton1
            onClick={handleQuickActive}
          >
            {quickActivate}
          </StyledButton1>
        </div>
      </div>

      <div>
        {/* --------------- Edit Modal ------------ */}
        <ReactModal className="ModalStyle1 Width90" isOpen={edit_std_tpc_modal}>
          <div>
            {" "}
            <StyledForm>
              {" "}
              <div align="center" className="mb-3">
                <h4>Change Topic Status</h4>
              </div>
              <StyledFormInputDiv>
                <div style={FormDivStyle} align="center">
                  <StyledLabel htmlFor="qdesc">Selected Topic</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Selected Topics"
                    value={topicdesc}
                    disabled={true}
                  />
                </div>
              </StyledFormInputDiv>
              <StyledFormInputDiv>
                <div style={FormDivStyle} align="center">
                  <StyledLabel htmlFor="qdesc">Status</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={tstatus_id}
                    onChange={(e) => {
                      setTstatus_id(e.target.value);
                    }}
                  >
                    <option hidden>Select Status</option>
                    {/* <option >New</option>
                    <option >Active</option> */}
                    {selection_viewstatuslist?.map((data, index) => {
                      return (
                        <option value={data.tstatusid}>{data.tstatusid}</option>
                      );
                    })}
                  </select>
                </div>
              </StyledFormInputDiv>
            </StyledForm>
          </div>
          <div align="center">
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                marginTop: 50,
              }}
              onClick={() => {
                console.log(status1, "Tstatus_id")
                console.log(topicdesc, "Topicdesc")
                selection_topicstatus_api_func();
              }}
            >
              Save
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                setEdit_std_tpc_modal(false);
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </ReactModal>
      </div>

      {/* <div className="mt-5 table-responsive tableFixHeadXLarge"> */}
      {table_name == "selected_topic" ? (
        <div
          style={{ height: '80vh', width: "100%", marginTop: 15 }}
          className={classes.root}
        >
          <DataGrid
            sx={{
              ".MuiDataGrid-columnHeaders": {
                backgroundColor: "#ff8c24",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
            }}
            rows={rows1}
            getRowId={(r) => r.id}
            id="_id"
            columns={columnsUsers}
            // initialState={{

            //   pagination: {
            //     pageSize: 10,
            //   },
            // }}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            //rowsPerPageOptions={[ 50, 100]}
            pagination
            hideFooterPagination={ rows1.length <= pageSize}
            //  processRowUpdate={processRowUpdate}
            experimentalFeatures={{ newEditingApi: true }}
          />

        </div>


        // <StyledTable>
        //   <thead>
        //     <ScrollTableHeader>
        //       <StyledTableHeaderData>S.No</StyledTableHeaderData>
        //       <StyledTableHeaderData style={{ paddingLeft: 15 }}>
        //         Selected Topics
        //       </StyledTableHeaderData>
        //       <StyledTableHeaderData style={{ paddingLeft: 13 }}>
        //         Status
        //       </StyledTableHeaderData>
        //       <StyledTableHeaderData>Action</StyledTableHeaderData>
        //     </ScrollTableHeader>
        //   </thead>
        //   <tbody>
        //     {selectedTopics?.map((data, index) => {
        //       return (
        //         <StyledTableRow>
        //           <td>{index + 1}</td>
        //           <td style={{ color: "black" }}>{data.topicdesc}</td>
        //           <td style={{ color: "black" }}>{data.tstatusid}</td>
        //           <td>
        //             <a
        //               onClick={() => {
        //                 setStatus1(data.tstatusid)
        //                 setTstatus_id(data.tstatusid);
        //                 setTopicid(data.topicid);
        //                 setTopiccodes(data.topiccodes);
        //                 setTopicdesc(data.topicdesc);
        //                 setEdit_std_tpc_modal(true);
        //                 console.log(status1, "Tstatus_id")
        //                 console.log(topicdesc,"Topicdesc")
        //               }}
        //             >
        //               <i class="fas fa-pencil-alt" aria-hidden="true"></i>
        //             </a>

        //             <a
        //               onClick={() => {
        //                 setStatus_info_modal(true);
        //                 selection_statusinfo_api_func();
        //               }}
        //             >
        //               <i
        //                 class="fas fa-info-circle"
        //                 style={{ marginLeft: 5 }}
        //               ></i>
        //             </a>
        //           </td>
        //         </StyledTableRow>
        //       );
        //     })}
        //   </tbody>
        // </StyledTable>
      ) : (


        <div
        style={{ height: '80vh', width: "100%", marginTop: 15 }}
        className={classes.root}
      >
        <DataGrid
          sx={{
            ".MuiDataGrid-columnHeaders": {
              backgroundColor: "#ff8c24",
            },
            "&.MuiDataGrid-root": {
              border: "none",
            },
          }}
          rows={rows}
          getRowId={(r) => r.topiccode}
          id="_id"
          columns={columnsAvailable}
          // initialState={{

          //   pagination: {
          //     pageSize: 10,
          //   },
          // }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[ 100]}
          pagination
          hideFooterPagination={ rows.length <= pageSize}
          //  processRowUpdate={processRowUpdate}
          experimentalFeatures={{ newEditingApi: true }}
        />

      </div>
        // <StyledTable>
        //   <thead>
        //     <ScrollTableHeader>
        //       <StyledTableHeaderData>
        //         S.No{" "}
        //         <a
        //           onClick={() => {
        //             setSortRev(!sortRev);
        //             setSortByAvlTopic(false);
        //             setSortBySno(true);
        //           }}
        //         >
        //           <i class="fas fa-sort" />
        //         </a>
        //       </StyledTableHeaderData>
        //       <StyledTableHeaderData style={{ paddingLeft: 10 }}>
        //         Available Topics{" "}
        //         <a
        //           onClick={() => {
        //             setSortRev(!sortRev);
        //             setSortByAvlTopic(true);
        //             setSortBySno(false);
        //           }}
        //         >
        //           <i class="fas fa-sort" />
        //         </a>
        //       </StyledTableHeaderData>

        //       <StyledTableHeaderData style={{ paddingLeft: 35 }}>
        //         Action
        //       </StyledTableHeaderData>
        //     </ScrollTableHeader>
        //   </thead>
        //   <tbody>
        //     {availableTopics?.map((data, index) => {
        //       return (
        //         <StyledTableRow>
        //           <td style={{ color: "black" }}>{index + 1}</td>
        //           {/* <td style={{ color: "black" }}>{data.topiccode}</td> */}
        //           <td style={{ color: "black" }}>{data.topicdesc}</td>

        //           <td>
        //             <StyledButtonSave
        //               onClick={() => {
        //                 selection_newtopic_api_func(data.topiccode);
        //               }}
        //               style={{ textAlign: "center", width: 100 }}
        //             >
        //               Select
        //             </StyledButtonSave>
        //           </td>
        //         </StyledTableRow>
        //       );
        //     })}
        //   </tbody>
        // </StyledTable>
      )}
      {/* </div> */}

      {/* modal for select status info */}
      <ReactModal className="ModalStyle3 Width90" isOpen={status_info_modal}>
        <div className="text-center">
          <a
            onClick={() => {
              setStatus_info_modal(false);
            }}
          >
            <i
              class="fas fa-times-circle"
              style={{ color: "red", fontSize: 40 }}
            ></i>
          </a>
        </div>
        <div className="mt-3 table-responsive">
          <StyledTable >
            <thead>
              <StyledTableHeader>
                <StyledTableHeaderData>S.No</StyledTableHeaderData>
                <StyledTableHeaderData>Description</StyledTableHeaderData>
                <StyledTableHeaderData>Status</StyledTableHeaderData>
                <StyledTableHeaderData>Editable</StyledTableHeaderData>
              </StyledTableHeader>
            </thead>
            <tbody>
              {select_status_infolist?.map((data, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td style={{ color: "black" }}>{data.tstatusdesc}</td>
                    <td style={{ color: "black" }}>{data.tstatusid}</td>
                    <td style={{ color: "black" }}>{data.custedit}</td>
                  </tr>
                );
              })}
            </tbody>
          </StyledTable>
        </div>
      </ReactModal>
    </div>
  );
};

export default Standard_Topics;
